import { computed, ref, Ref, unref } from "vue";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { DateTime } from "luxon";
import { EnergyConsumption, MaybeRef } from "@/types";

export interface UseEnergyConsumptionOptions {
  enabled?: MaybeRef<boolean>;
}

export interface UseEnergyConsumptionResult {
  energyConsumptionByTime: Ref<EnergyConsumption | undefined>;
  loadingError: Ref<boolean>;
  refetch: () => void;
  loading: Ref<boolean>;
}

export function useEnergyConsumption(
  assetUuid: MaybeRef<string | null>,
  startDate: MaybeRef<DateTime>,
  endDate: MaybeRef<DateTime>,
  { enabled = true }: UseEnergyConsumptionOptions = {}
): UseEnergyConsumptionResult {
  const energyConsumptionByTime = ref<EnergyConsumption | undefined>();
  const loadingError = ref(false);
  const startDateUtc = computed(() => unref(startDate).setZone("utc"));
  const endDateUtc = computed(() => unref(endDate).setZone("utc"));

  const { onResult, onError, refetch, loading } = useQuery(
    gql`
      query EnergyQuery($deviceUuid: ID!, $startDate: DateTime!, $endDate: DateTime!) {
        energyConsumptionByTime(deviceUuid: $deviceUuid, startDate: $startDate, endDate: $endDate) {
          points {
            time
            kwhEst
            kwhActual
          }
        }
      }
    `,
    {
      deviceUuid: assetUuid,
      startDate: startDateUtc,
      endDate: endDateUtc,
      timestamp: DateTime.local() // Ignored, but ensures that variables are never the same
    },
    () => ({
      enabled: unref(enabled) && unref(assetUuid) !== null && unref(startDate) !== null && unref(endDate) !== null,
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true
    })
  );

  onError(() => {
    loadingError.value = true;
  });

  onResult(queryResult => {
    if (queryResult.data) {
      energyConsumptionByTime.value = queryResult.data.energyConsumptionByTime;
    }
  });

  return { energyConsumptionByTime, loadingError, refetch, loading };
}
